//main colors
$color-primary: #409B7E;
$color-primary-beta: #1d9f75;
$color-secondary: #e85743;
$color-secondary-beta: #c63f2c;
$color-tertiary: #ffcb00;
$color-tertiary-beta: #fea80a;
$color-error: #ef3f2c;
$color-success: #56b532;

$color-grey : #EDEEEE;

//fonts colors
$color-font-dark: #16314b;
$color-font-extra-dark: #00243e;
$color-font-medium: #6d7175;
$color-font-light: #fff;


//background colors
$color-background: #ffe9e1;
$color-background-beta: #eef4f1;
$color-background-gamma: #fff3ec;


//Font
$main-font: "Be Vietnam Pro";
$alt-font: "KGAlwaysAGoodTime";

// PrestaShop Blocks
$ps-border-color : #d6d4d4;

// PrestaShop Products
$product-price-reduction-bg    : #f00;
$product-price-reduction-color : #fff;

$product-price-special-color   : #f00;
$product-price-old-color       : #b1b1b1;

// PrestaShop Product Labels
// @TODO SASS label colors

//
// Override vendor variables here
//

// Bootstrap
$border-radius-base  : 0;
$border-radius-large : 0;
$border-radius-small : 0;
// @TODO Override top navbar collapse point

$font-size-base: 14px; // Default is 14px
$font-size-h1: floor(($font-size-base * 2.1)); // ~29px (default: 36px)
$font-size-h2: floor(($font-size-base * 1.8)); // ~25px (default: 30px)
$font-size-h3: ceil(($font-size-base * 1.6));  // ~23px (default: 24px)
$font-size-h4: ceil(($font-size-base * 1.4));  // ~20px (default: 18px)
$font-size-h5: ceil(($font-size-base * 1.2));  // ~17px (default: 14px)
$font-size-h6: $font-size-base;                // ~14px (default: 12px)

// @TODO Overwrite theses variables to customize the colors of Bootstrap:

// @see themes/community-theme-16/sass/vendor/bootstrap/bootstrap/_variables.scss
$brand-primary:  $color-primary !default; // #337ab7
// $brand-success:  #5cb85c;
// $brand-info:     #5bc0de;
// $brand-warning:  #f0ad4e;
// $brand-danger:   #d9534f;

// @see themes/community-theme-16/sass/vendor/bootstrap/bootstrap/_variables.scss
// $state-success-bg: #dff0d8;
// $state-info-bg:    #d9edf7;
// $state-warning-bg: #fcf8e3;
// $state-danger-bg:  #f2dede;

// @see themes/community-theme-16/sass/vendor/bootstrap/bootstrap/mixins/_forms.scss
// Change the glow color of .form-control when in focus
$input-border-focus: $brand-primary;

// Font Awesome
$font-icon     : 'FontAwesome';
$fa-css-prefix : icon;
$fa-font-path: "../fonts/font-awesome";

// Custom mixins + bourbon
@import "bourbon";
@import "vendor_mixins";
