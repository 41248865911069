@import "vendor/bootstrap/bootstrap/variables";
@import "theme_variables";

#index {
    .homeslider-container {
        display: flex;
        justify-content: center;
    }
    .home-banner {
        .home-slider-link {
            border-radius: 25px;
            overflow: hidden;
            display: flex;
            img {
                width: 100%;
                height: 100%;
                max-height: 568px;
                object-fit: cover;
            }
        }
        @media (min-width: $screen-lg-desktop) {
            width: 1440px;

            .slick-slide {
                margin-right: 40px;
                margin-left: -20px;
                width: 780px;
            }
            /* the parent */
            .slick-list {
                margin: 0 -30px;
            }
        }
        @media (max-width: $screen-md-max) {
            width: 100vw;
            max-width: 100vw;
            margin-bottom: unset;
            .slick-slide {
                /*margin: 0 -25px;*/
                width: 350px;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-right: 20px;
                margin-left: -30px;
            }
            height: 250px!important;
            padding: unset;
            .home-banner {
                margin-top: 50px;

            }
        }

        margin-top: 150px;
        height: 568px;
        margin-bottom: 40px;

        .slick-dots {
            position: relative;
            li {
                button {
                    width: 25px;
                    height: 25px;
                    &:before {
                        color: $color-primary;
                        opacity: 0.5;
                        width: 25px;
                        height: 25px;
                        font-size: 25px;
                        line-height: 25px;
                    }
                }
                &.slick-active {
                    button:before {
                        color: $color-primary;
                        opacity: 1!important;
                    }
                }
            }
        }
        .slick-dotted.slick-slider {
            margin-bottom: 1rem;
        }



        @media (min-width: $screen-sm-min) {
            height: 485px;
            border-radius: 25px;
            margin-bottom: 25px;
        }

        /*background-image: url('../img/home/img-banner@2x.jpg');
        background-position: top;
        background-size: cover;*/

        padding-bottom: 80px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 96px;
        }
    }

    .cta-row{
        
        h1 {
            font-family: $alt-font;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: $color-font-dark;

            font-size: 32px;
            margin-bottom: 16px;

            @media (min-width: $screen-sm-min) {
                font-size: 40px;
                margin-bottom: 24px;
            }
        }


        padding-bottom: 80px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 96px;
        }

        
    }

    .cta2-row {

        padding-bottom: 56px;
        margin-top: 20px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 128px;
        }

    }

    .part-row {
        
        padding-top: 56px;
        padding-bottom: 56px;

        @media (min-width: $screen-sm-min) {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        text-align: center;
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }

        h2, h3 {
            margin-top: 0;
        }

        h3{
            margin-bottom: 4px;
        }
    }

    .commitment-row {

        padding-top: 56px;
        padding-bottom: 56px;

        @media (min-width: $screen-sm-min) {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        h2, h3 {
            margin-top: 0;
        }

        h3{
            margin-bottom: 4px;
        }

        .commitment-item {
            display: flex;
            align-items: flex-start;
            img{
                margin-right: 15px;
                margin-top: 10px;
            }

            div{
                margin-top:10px;
            }
        }
    }

    .artisan-row {

        padding-top: 56px;
        padding-bottom: 56px;

        @media (min-width: $screen-sm-min) {
            padding-top: 96px;
            padding-bottom: 96px;
        }

        h2, h3 {
            margin-top: 0;
        }

        h2 {
            @media (min-width: $screen-sm-min) {
                margin-bottom: 40px;
            }
        }

        .plus {

            .btn {
                padding-left: 0;
            }

            @media (min-width: $screen-sm-min) {
                text-align: right;
            }

            margin-bottom: 24px;
        }
    }
}
ul {
    &.event-list {
        display: grid;
        row-gap: 1rem;
        li {
            &.event-list__item {
                display: flex;
                div {
                    &.event-list__item {
                        &__icon {
                            margin-right: 12px;
                        }
                        &__info {
                            > h3 {
                                margin-top: unset;
                            }
                            &__titre {

                            }
                        }
                    }
                }
            }
        }
    }
}
div.shape-list-item {
    display: grid;
    row-gap: 1rem;
}

.commitment-row {
    .triptych {
        svg {
            display: none;
        }
    }
    svg {
        margin-right: 15px;
        margin-top: 10px;
    }
}

.commitment-row{
    &.shape-green-right::before {
        right: -515px;
        top: -235px;
    }
}
.commitment-item-3 {
    svg {
        @media (max-width: $screen-sm) {
            width: 138px;
        }

    }
}

.best-food {
    .best-food-img {
        width: 410px;
        height: 742px;
        border-radius: 24px;
        padding-left: 24px;
        padding-right: 24px;
        background: url(../img/home/shape-bike.jpeg);
        background-size: cover;
        background-position: bottom;
        @media (max-width: $screen-sm) {
            width: 350px;
            height: 350px;
        }
    }
}
.transition-block {
    height: 512px;
    padding-top: 6rem;
    padding-bottom: 6rem;
    > .row {
        height: 100%;
    }
    @media (max-width: $screen-sm) {
        padding-top : 3.5rem;
        padding-bottom: 3.5rem;
        height: 644px;
    }
}
.transition-row {
    @media (max-width: $screen-sm) {
        display: flex;
        align-items: center;
        padding-top : 3rem;
        padding-bottom: 3rem;
        height: 644px;
    }
    > .row {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $screen-sm) {
            flex-direction: column;
        }
    }
    .transition-row-texte {
        @media (max-width: $screen-sm) {
            > p {
                margin-bottom: 40px!important;
            }
        }
    }
}

.evenement-block {
    .cta2-row {
        a.btn.btn-primary {
            margin-right: unset!important;
        }
    }
    .item-column {
        svg {
            @media (max-width: $screen-sm) {

            }
        }
    }
    .triptych.triptych-1  {
        @media (max-width: $screen-sm) {
            .item-1 {
                height: 247px;
                margin-top: 80px;
            }
            .item-2 {
                height: 176px;
            }
            .item-3 {
                height: 144px;
            }
        }
    }
}